.background-image {
    width: 100%;
}

.content {
    background: #f1f1e5 !important;
}

#form-input-first-name {
    background: #fcfcf4 !important;
    color: black;
}

#author-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Use min-height instead of height */
  }
  
  .author-content {
    flex-grow: 1; /* This will make the content take up the remaining space */
  }
  
  .page-footer {
    flex-shrink: 0; /* This will prevent the footer from growing and stick to the bottom */
  }