.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

.polygon {
  fill:rgb(255, 255, 255);stroke-width:3;
  stroke:rgb(255, 249, 249);stroke-opacity:0.5;
  opacity:0.5;
  /* clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%); */
}


.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.pagination {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  
}

.pagination li {
  list-style-type: none;
  display: inline-block;
}
.pagination li p {
  position: relative;
  padding: 20px 25px;
  text-decoration: none;
  color: #f0eec5;
  font-weight: 500;
  background: #5a878a;
}
.pagination li p:hover,
.pagination li.active p {
  background: #213c56;
}

.first {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.next {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media (max-width: 600px) {
  .pagination {
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    padding: 0;
  }

  .pagination li p {
    padding: 10px 10px;
  }
}

.diamondText {
  color: white;
  /* // position: absolute;
  // top: 50%;
  // left: 50%; */
  transform: rotate(-45deg);
  font-size: 16px;
  opacity: 1 !important;
}


  .diamondHeader {
    position: relative;
    left: -5%;
}
@media (max-width: 1200px) {
  .diamondHeader {
    position: relative;
    left: 0%;
  }
}
@media (max-width: 1000px) {
  .diamondHeader {
    position: relative;
    left: 5%;
  }
}

.circleHeader {
  position: relative;
  left: 0%;
}
@media (max-width: 1200px) {
.circleHeader {
  position: relative;
  left: 5%;
}
}
@media (max-width: 1000px) {
.circleHeader {
  position: relative;
  left: 10%;
}
}

.diamond {
  width: 350px;
  height: 350px;
  background-color: #333;
  transform: rotate(45deg);
  position: relative;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-top: 177px;
  margin-bottom: 184px;
  /* // margin-right: ${props => `${props.size/2.75}px`};
  // margin-left: ${props => `${props.size}px`}; */
  transform-origin: center center; 
  left: 15%;
}

.circle {
  width: 400px;
  height: 400px;
  background-color: #333;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 135px;
  margin-bottom: 135px;
  /* margin-right: ${props => `${props.size}px`};
  margin-left: ${props => `${props.size}px`}; */
  transform-origin: center center; 
  left: 15%;
  position: relative
}

@media (max-width: 1000px) {
  .diamond {
    width: 280px;
    height: 280px;
    background-color: #333;
    transform: rotate(45deg);
    position: relative;
    /* overflow: hidden; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-top: 142px;
    margin-bottom: 149px;
    /* margin-left: 125px; */
    /* // margin-right: ${props => `${props.size/2.75}px`};
    // margin-left: ${props => `${props.size}px`}; */
    transform-origin: center center; 
    left: 15%;

  }
}


.circleText {
  color: white;
  font-size: 20px;
}

@media (max-width: 1000px) {
  .circle {
    width: 330px;
    height: 330px;
    background-color: #333;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
    transform-origin: center center; 
    left: 15%;
    position: relative
    /* margin-right: 90px; */
    /* margin-left:120px; */
    /* margin-right: ${props => `${props.size}px`};
    margin-left: ${props => `${props.size}px`}; */
  }
}
