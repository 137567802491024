body {
    font-family: 'Roboto', sans-serif;
    background-color: #6C7A86 ;
  }

  #navBar {
    width: auto;
  }
  
  #menu {
    z-index: 2;
  }
  
  #menu-bar {
    width: 45px;
    height: 40px;
    margin: 30px 0 20px 25px;
    cursor: pointer;
  }
  
  .bar {
    height: 5px;
    width: 100%;
    background-color: #1F3252;
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
  }
  
  #bar1 {
    transform: translateY(-4px);
  }
  
  #bar3 {
    transform: translateY(4px);
  }
  
  .nav {
    transition: 0.4s ease;
    display: none;
  }
  
  .nav ul {
    padding: 0 22px;
  }
  
  .nav li {
    list-style: none;
    padding: 12px 0;
  }
  
  .nav li a {
    color: #1F3252;
    font-size: 20px;
    text-decoration: none;
  }
  
  .nav li a:hover {
    font-weight: bold;
  }
  
  .menu-bg, #menu {
    top: 0;
    left: 0;
    position: absolute;
  }
  
  .menu-bg {
    z-index: 1;
    width: 0;
    height: 0;
    margin: 30px 0 20px 20px;
    background: radial-gradient(circle, #F49D03, #F49D03);
    border-radius: 50%;
    transition: 0.4s ease;
    opacity: 30%;
  }
  
  .change {
    display: block;
  }
  
  .change .bar {
    background-color: #1F3252;
  }
  
  .change #bar1 {
    transform: translateY(4px) rotateZ(-45deg);
  }
  
  .change #bar2 {
    opacity: 0;
  }
  
  .change #bar3 {
    transform: translateY(-6px) rotateZ(45deg);
  }
  
  .change-bg {
    width: 320px;
    height: 360px;
    transform: translate(-60%,-30%);
  }